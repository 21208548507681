var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center"},[(_vm.procedures.length == 0)?_c('span',{staticClass:"text-caption font-weight-bold orange--text darken-4"},[_vm._v(" Don't have procedures, add procedures"),_c('br'),_vm._v(" by pressing in the button on the left. ")]):[_c('v-dialog',{attrs:{"persistent":"","width":"auto","content-class":"rounded-xl"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","depressed":"","rounded":""}},'v-btn',attrs,false),on),[_vm._v(" Procedure ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"\n            headline\n            primary\n            white--text\n            align-center\n            justify-space-between\n          "},[_vm._v(" Select procedure "),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-window-close")])],1)],1),_c('v-card-text',{staticClass:"btn-procedures pt-3 overflow-y-auto pa-0",staticStyle:{"max-height":"500px"}},[_c('div',{staticClass:"d-flex px-4 mb-3"},[_c('v-select',{staticStyle:{"max-width":"70%"},attrs:{"label":"Procedure type","items":_vm.proceduresTypes,"item-text":"label","item-value":"value","loading":_vm.loadingSubtype,"hide-details":"","outlined":"","dense":""},model:{value:(_vm.proceduresType),callback:function ($$v) {_vm.proceduresType=$$v},expression:"proceduresType"}}),_c('v-checkbox',{staticClass:"mx-3",attrs:{"label":"View Canceled"},model:{value:(_vm.seeCancels),callback:function ($$v) {_vm.seeCancels=$$v},expression:"seeCancels"}})],1),_c('v-divider'),_c('div',{staticClass:"procedures-list"},[_c('v-data-table',{class:_vm.seeCancels ? 'canceled' : '',attrs:{"headers":_vm.headers,"items":_vm.proceduresFiltered,"items-per-page":10,"item-key":"id"},on:{"click:row":_vm.setProcedure},scopedSlots:_vm._u([{key:"item.total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.total))+" ")]}},{key:"item.deb",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.deb))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.seeCancels)?_c('btn-remove-procedure',{attrs:{"procedureProp":item},on:{"removed":function($event){return _vm.$emit('changed')}}}):_vm._e()]}}],null,true)},[_vm._v(" "+_vm._s(/* Actions */)+" ")]),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"700px","transition":"dialog-transition"},model:{value:(_vm.procPaymentTrasf.dialog),callback:function ($$v) {_vm.$set(_vm.procPaymentTrasf, "dialog", $$v)},expression:"procPaymentTrasf.dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Payments "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.cancelprocpayments}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Payment type")]),_c('th',{staticClass:"text-left"},[_vm._v("Amount")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")]),_c('th',{staticClass:"text-left"},[_vm._v("Payment date")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.procPaymentTrasf.payments),function(pay,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(pay.type))]),_c('td',[_c('b',{staticClass:"green--text"},[_vm._v(_vm._s(_vm._f("currency")(pay.amount)))])]),_c('td',[_c('span',{class:pay.status == 'CONFIRMED'
                                    ? 'green--text'
                                    : 'red--text'},[_vm._v(" "+_vm._s(pay.status == "CONFIRMED" ? pay.status : "FAIL!"))])]),_c('td',[_vm._v(_vm._s(_vm.paymentDate(pay.createAt)))]),_c('td',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3",attrs:{"icon":"","color":"success"},on:{"click":function($event){return _vm.toTransfer(pay, 'self')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shuffle-disabled")])],1)]}}],null,true)},[_c('span',[_vm._v("Transfer Payment")])])],1)])}),0)]},proxy:true}])})]],2)],1)],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","overlay":false,"max-width":"300px","transition":"dialog-transition"},model:{value:(_vm.transfer.transferdialog),callback:function ($$v) {_vm.$set(_vm.transfer, "transferdialog", $$v)},expression:"transfer.transferdialog"}},[[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 white--text green lighten-2",attrs:{"primary-title":""}},[_vm._v(" Transfer Payment ")]),_c('v-card-text',[_c('div',{staticClass:"pa-2"},[_vm._v(" Amount : "+_vm._s(_vm._f("currency")(_vm.transfer.transferamount))+" ")]),_c('v-autocomplete',{attrs:{"items":_vm.procedurestotrasfer,"outlined":"","dense":"","item-value":"id","item-text":"name"},scopedSlots:_vm._u([{key:"item",fn:function(data){return [[_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.name)}}),_c('v-list-item-subtitle',{domProps:{"innerHTML":_vm._s('ID:' + data.item.id)}})],1)]]}}]),model:{value:(_vm.transfer.transferToProcedure),callback:function ($$v) {_vm.$set(_vm.transfer, "transferToProcedure", $$v)},expression:"transfer.transferToProcedure"}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":_vm.canceltransfer}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"disabled":_vm.transfer.transferToProcedure == '' ||
                _vm.transfer.transferToProcedure == null ||
                _vm.transfer.transferToProcedure == undefined,"loading":_vm.transfer.loading,"text":"","color":"primary"},on:{"click":_vm.confirmTransfer}},[_vm._v("Transfer")])],1)],1)]],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }