<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <v-card>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :headers="headers"
          :items="salesAccessories"
          class="elevation-1"
          :options.sync="options"
          single-expand
          show-expand
          :expanded.sync="expanded"
          :footer-props="footerProps"
        >
          <template v-slot:[`item.createdBy`]="{ item }">
            {{ item.createdBy.fullname }}
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span :class="`${getColor(item.status)}--text`">
              {{ item.status }}</span
            >
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ paymentDate(item.createdAt) }}
          </template>
          <template v-slot:[`item.totalAmount`]="{ item }">
            {{ item.totalAmount | currency }}
          </template>
          <template v-slot:[`item.discount`]="{ item }">
            {{ item.discount | currency }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <template
              v-if="(isAdmin || isSuper) && item.status == 'UNCONFIRMED'"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="goToAction(item, 'cancel')"
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="error"
                    ><v-icon>mdi-cancel</v-icon></v-btn
                  >
                </template>
                <span>Cancel Sale</span>
              </v-tooltip>
            </template>

            <template v-if="(isAdmin || isSuper) && item.status == 'CONFIRMED'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="goToAction(item, 'refund')"
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="warning"
                    ><v-icon>mdi-autorenew</v-icon></v-btn
                  >
                </template>
                <span>Refund Sale</span>
              </v-tooltip>
            </template>
            <template v-if="item.status == 'CONFIRMED'">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="goToInvoice(item)"
                    v-on="on"
                    v-bind="attrs"
                    icon
                    color="success"
                    ><v-icon>mdi-note-text</v-icon></v-btn
                  >
                </template>
                <span>View Sale</span>
              </v-tooltip>
            </template>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <div class="d-flex ma-4">
                <div class="col-sm-4">
                  <div>
                    <span><strong>Sale:</strong> #{{ item.id }}</span>
                  </div>
                  <div class="my-4">
                    <span
                      ><strong>Employee:</strong>
                      {{ item.createdBy.fullname }}</span
                    >
                  </div>
                  <div class="my-4">
                    <span
                      ><strong>Amount:</strong>
                      {{ item.totalAmount | currency }}</span
                    >
                  </div>
                  <div class="my-4">
                    <span
                      ><strong>Discount:</strong>
                      {{ item.discount | currency }}</span
                    >
                  </div>
                  <div class="my-4">
                    <span
                      ><strong>Created At:</strong>
                      {{ paymentDate(item.createdAt) }}</span
                    >
                  </div>
                </div>
                <div class="col-sm-6">
                  <template>
                    <v-card>
                      <v-card-title primary-title>
                        {{ nomAccesory }}
                      </v-card-title>

                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">{{ showProd }}</th>
                              <th class="text-left" align-end>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(p, index) in item.products"
                              :key="index"
                            >
                              <td>{{ p.description }}</td>
                              <td>
                                {{ p.balance | currency }}
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>
                  </template>
                </div>
                <template v-if="item.status == 'UNCONFIRMED'">
                  <div class="col-sm-2 d-flex align-center">
                    <!--   <v-btn color="success">Paid</v-btn> -->
                  </div>
                </template>
              </div>
            </td>
          </template>
        </v-data-table>
        <v-dialog
          v-model="invoice.dialogInvoice"
          persistent
          :overlay="false"
          max-width="600px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title primary-title>
              <v-spacer></v-spacer>
              <v-btn color="success" class="mx-2" icon @click="generateReport"
                ><v-icon>mdi-file-pdf</v-icon> Export
              </v-btn>
            </v-card-title>
            <v-card-text class="mt-4">
              <template v-if="invoice.item != null">
                <div id="pdf" class="mx-4 mt-4">
                  <h2 class="bg-custom1">Payment confirmation</h2>
                  <template v-if="invoice.item.patient">
                    <div class="patient">
                      <h5>{{ getpatientN }}</h5>
                    </div>
                    <p class="mx-0">
                      {{ invoice.item.patient.fullname }}
                    </p>
                    <p class="textemail font-italic">
                      {{ invoice.item.patient.email }}
                    </p>
                  </template>

                  <v-divider></v-divider>
                  <div class="row fact-info my-1 mx-2">
                    <div class="col-3">
                      <h5>Facture :</h5>
                      <p>{{ "No." + getNoFacture(invoice.item) }}</p>
                    </div>
                    <div class="col-3">
                      <h5>Payment Type</h5>
                      <p>{{ getpaymentType(invoice.item.payment) }}</p>
                    </div>
                    <div class="col-3">
                      <h5>Final Amount</h5>
                      <p>{{ invoice.item.payment.amount | currency }}</p>
                    </div>
                    <div class="col-3">
                      <h5>Discount</h5>
                      <p>{{ invoice.item.discount | currency }}</p>
                    </div>
                  </div>
                  <v-divider class="mt-1"></v-divider>
                  <div class="products">
                    <h3>{{ nomAccesory }}</h3>
                  </div>

                  <div class="row fact-info mt-3">
                    <v-simple-table class="margins">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-center">{{ showProd }}</th>
                            <th class="text-center" align-end>Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(p, index) in invoice.item.products"
                            :key="index"
                          >
                            <td class="text-center">
                              {{ p.description }}
                            </td>
                            <td class="text-center">
                              {{ p.balance | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                  <v-divider></v-divider>
                </div>
              </template>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelInvoice" color="error">Cancel</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="actionsSales.dialogActions"
          persistent
          :overlay="false"
          max-width="300px"
          transition="dialog-transition"
        >
          <v-card>
            <v-card-title
              :class="
                actionsSales.typeAction == 'refund'
                  ? 'text-h5 white--text  orange lighten-2'
                  : 'text-h5 white--text  red lighten-2'
              "
            >
              {{
                actionsSales.typeAction == "refund"
                  ? "Refund Sale"
                  : "Cancel Sale"
              }}
            </v-card-title>
            <v-card-text class="d-flex justify-center my-3 text-h6">
              Are you sure?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="cancelAction" text>Cancel</v-btn>
              <v-btn
                @click="confirmActions(actionsSales.typeAction)"
                text
                :color="
                  actionsSales.typeAction == 'refund'
                    ? 'orange lighten-2'
                    : 'red lighten-2'
                "
                :disabled="loading"
                :loading="loading"
                >Confirm</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import moment from "moment";
import Vue from "vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
export default Vue.extend({
  name: "crm-patient-accessories",
  data() {
    return {
      loading: false,
      invoice: {
        dialogInvoice: false,
        item: null,
      },
      actionsSales: {
        dialogActions: false,
        typeAction: "refund",
        saleId: null,
      },
      options: {},
      expanded: [],
      footerProps: { "items-per-page-options": [10, 15, 50, 100] },
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Employee", value: "createdBy", sortable: false },
        { text: "Amount", value: "totalAmount", align: "end", sortable: false },
        { text: "Discount", value: "discount", align: "end", sortable: false },
        {
          text: "Paid",
          value: "status",
          align: "center",
          sortable: false,
        },
        { text: "Created At", value: "createdAt", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
        { text: "", value: "data-table-expand", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["nomAccesory", "isAdmin", "isSuper", "getpatientN"]),
    ...mapState("crmMedicFormModule", ["patientDetail", "salesAccessories"]),

    showProd() {
      if (this.nomAccesory == "Accessories") {
        return "Accessory";
      }
      return this.nomAccesory.substring(0, this.nomAccesory.length - 1);
    },
  },
  methods: {
    ...mapActions("crmMedicFormModule", ["actSalesAccessories"]),
    ...mapActions("crmConfigurationsModule", [
      "actCancelSale",
      "actRefundSale",
    ]),

    goToInvoice(item) {
      this.invoice = { dialogInvoice: true, item: item };
    },
    getNoFacture(item) {
      return item.id;
    },
    getpaymentType(item) {
      if (item.type == "External_Card_Reader") {
        return "Stripe Card Reader";
      }
      return item.type;
    },
    generateReport() {
      window.html2canvas = html2canvas;
      let doc = new jsPDF("p", "pt", "a4");
      doc.html(document.querySelector("#pdf"), {
        callback: function (pdf) {
          pdf.save("Payment-confirm.pdf");
        },
      });
      this.cancelInvoice();
    },

    cancelInvoice() {
      this.invoice = { dialogInvoice: false, item: null };
    },

    goToAction(item, type) {
      this.actionsSales = {
        dialogActions: true,
        typeAction: type,
        saleId: item.id,
      };
    },
    cancelAction() {
      this.actionsSales = {
        dialogActions: false,
        typeAction: "refund",
        saleId: null,
      };
    },
    async confirmActions(type) {
      this.loading = true;
      if (type == "refund") {
        await this.actRefundSale(this.actionsSales.saleId);
      } else {
        await this.actCancelSale(this.actionsSales.saleId);
      }

      await this.getAccessories();
      this.cancelAction();
      this.loading = false;
    },
    getAccessories() {
      const body = {
        range: { limit: 100, offset: 0 },
        patientUuid: this.patientDetail.uuid,
      };
      this.actSalesAccessories(body);
    },

    prettyDate(date) {
      if (date === null || date === "") return "";

      return moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
    },
    paymentDate(date) {
      return new Date(date).toISOString().slice(0, 10);
    },
    getColor(value) {
      switch (value) {
        case "CONFIRMED":
          return "green";
        case "REFOUNDED":
          return "orange";
        case "UNCONFIRMED":
          return "red";
        default:
          return "gray";
      }
    },
  },
  async mounted() {
    this.loading = true;
    await this.getAccessories();
    this.loading = false;
  },
});
</script>
<style lang="scss" scope>
.tooltipnote {
  max-width: 650px !important;
  padding: 10px !important;
}
.margins {
  width: 100%;
}
.textemail {
  margin-top: -15px;
}
.products {
  text-align: center;
}
#pdf {
  margin: 15px;
  height: 300px;
  width: 500px;
  position: relative;
}

.fact-info {
  margin-top: 5px;
  text-align: justify;
  justify-content: center;
}
.textemail {
  margin-top: 5px !important;
  margin-bottom: 15px;
}
.bg-custom1 {
  margin-top: 2rem;
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;

  border-radius: 0.25em;

  margin: 0 0 1em;
  padding: 0.5em 0;
}
</style>
