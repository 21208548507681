






























































import {
  notifyError,
  notifyInfo,
  notifySuccess,
} from "@/components/Notification";
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default Vue.extend({
  name: "btn-remove-procedure",
  props: {
    procedureProp: Object,
  },
  data() {
    return {
      dialogConfirmRemove: false,
      loading: false,
    };
  },
  computed: {
    ...mapState("crmMedicFormModule", ["procedure"]),
  },
  methods: {
    ...mapActions("crmDashboardModule", ["actRemoveProcedureStatus"]),
    ...mapMutations("crmMedicFormModule", ["mutProcedure"]),
    remove() {
      this.loading = true;

      this.actRemoveProcedureStatus({ id: this.procedureProp.id })
        .then(() => {
          this.dialogConfirmRemove = false;
          this.loading = false;
          notifyInfo(this.$t("notifyRemove"));
          if (this.procedure.id == this.procedureProp.id) {
            this.mutProcedure(null);
          }
          this.$emit("removed");
        })
        .catch((error) => {
          let mess = error.response.data.message.toString();
          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.dialogConfirmRemove = false;
          this.loading = false;
        });
    },
  },
});
