
































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from "vue";
import BtnRemoveProcedure from "./BtnRemoveProcedure.vue";
import { mapMutations, mapState } from "vuex";
import { getAPI } from "@/api/axios-base";
import { notifyError } from "@/components/Notification";
import moment from "moment";
import MaDatePicker from "@/components/MaDatePicker/MaDatePicker.vue";
// type Subtype = "Surgery" | "Treatment";

export default Vue.extend({
  name: "btn-procedures",
  components: {
    BtnRemoveProcedure,
    MaDatePicker,
  },
  props: {
    procedures: Array,
  },
  data() {
    return {
      seeCancels: false,

      dialogEdit: false,
      surgicalDate: null,
      idSelect: null,
      loadingEditPro: false,

      dialog: false,
      contador: 0,
      transfer: {
        loading: false,
        transferdialog: false,
        transferitems: [],
        transferamount: 0,
        transferToProcedure: "",
      },
      procPaymentTrasf: {
        dialog: false,
        procedure: null,
        payments: [],
      },
      loadingSubtype: false,
      procedure: "Select procedure",
      proceduresType: "",
      proceduresTypes: [
        { label: "Select all", value: "" },
        { label: "Surgery", value: "Surgery" },
        { label: "Treatment", value: "Treatment" },
      ],
      procedureSubtype: "",
      procedureSubtypes: [],
      headers: [
        { text: "ID", value: "id", sortable: false },
        { text: "Type", value: "type", sortable: false },
        { text: "Subtype", value: "name", sortable: false },
        { text: "Doctor", value: "doctor", sortable: false },
        { text: "Date", value: "date", sortable: false },
        { text: "Total", value: "total", sortable: false, align: "end" },
        { text: "Amount due", value: "deb", sortable: false, align: "end" },
        { text: "", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState("crmDashboardModule", ["procedureSelectid"]),
    ...mapState(["profile"]),
    proceduresFiltered(): any[] {
      let procedureFilter = null;

      if ((this as any).seeCancels) {
        procedureFilter = this.procedures.filter(
          (procedure: any) =>
            procedure.type == (this as any).proceduresType &&
            procedure.subtype == (this as any).procedureSubtype &&
            procedure.status == "CANCELED"
        );
      } else {
        procedureFilter = this.procedures.filter(
          (procedure: any) =>
            procedure.type == (this as any).proceduresType &&
            procedure.subtype == (this as any).procedureSubtype &&
            procedure.status == "ACTIVE"
        );
      }
      if (
        (this as any).proceduresType == "" &&
        (this as any).procedureSubtype == "" &&
        (this as any).seeCancels == false
      ) {
        procedureFilter = this.procedures.filter(
          (procedure: any) => procedure.status == "ACTIVE"
        );
      }

      if (
        (this as any).proceduresType == "" &&
        (this as any).procedureSubtype == "" &&
        (this as any).seeCancels == true
      ) {
        procedureFilter = this.procedures.filter(
          (procedure: any) => procedure.status == "CANCELED"
        );
      }

      if (
        (this as any).proceduresType != "" &&
        (this as any).procedureSubtype == "" &&
        (this as any).seeCancels == false
      ) {
        procedureFilter = this.procedures.filter(
          (procedure: any) =>
            procedure.type == (this as any).proceduresType &&
            procedure.status == "ACTIVE"
        );
      }

      if (
        (this as any).proceduresType != "" &&
        (this as any).procedureSubtype == "" &&
        (this as any).seeCancels == true
      ) {
        procedureFilter = this.procedures.filter(
          (procedure: any) =>
            procedure.type == (this as any).proceduresType &&
            procedure.status == "CANCELED"
        );
      }

      return procedureFilter;
    },
    procedurestotrasfer() {
      if (this.procedures.length == 0) {
        return [];
      }
      const res = this.procedures.filter((p: any) => p.status != "CANCELED");

      if (res == undefined) {
        return [];
      }
      return res;
    },
  },

  mounted() {
    if ((this as any).procedureSelectid == 0) {
      if (this.procedures.length > 0) {
        (this as any).procedure = (this as any).procedures[0].name;
        (this as any).setProcedure((this as any).procedures[0]);
      }
    } else {
      const proced = (this as any).procedures.find(
        (item: any) => item.id == (this as any).procedureSelectid
      );
      (this as any).procedure = proced;
      (this as any).setProcedure(proced);
    }
  },
  methods: {
    ...mapMutations("crmMedicFormModule", ["mutProcedure"]),
    setProcedure(procedure: any): void {
      if (
        (this as any).seeCancels &&
        ((this as any).profile.rol == "SUPER" ||
          (this as any).profile.rol == "ADMIN")
      ) {
        (this as any).procPaymentTrasf.dialog = true;
        (this as any).procPaymentTrasf.procedure = procedure;
        (this as any).procPaymentTrasf.payments = procedure.payments;
      } else {
        (this as any).mutProcedure(procedure);
        (this as any).procedure = procedure.name;
        (this as any).dialog = false;
        this.$emit("selected");
      }
    },

    cancelprocpayments() {
      (this as any).procPaymentTrasf = {
        dialog: false,
        procedure: null,
        payments: [],
      };
    },
    toTransfer(item: any, type: string) {
      (this as any).transfer.transferdialog = true;
      if (type == "self") {
        (this as any).transfer.transferitems = [item.uuid];
        (this as any).transfer.transferamount = item.amount;
      } else {
        let amount = 0;
        const arry = (this as any).selected.map((sel: any) => {
          amount = amount + Number(sel.amount);
          return sel.uuid;
        });
        (this as any).transfer.transferitems = arry;
        (this as any).transfer.transferamount = amount;
      }
    },
    confirmTransfer() {
      (this as any).transfer.transferitems.forEach(async (element: any) => {
        (this as any).transfer.loading = true;
        getAPI
          .put("/payment/transfer-payment", {
            paymentUuid: element,
            procedureId: (this as any).transfer.transferToProcedure,
          })
          .then(() => {
            (this as any).contador++;
            this.$emit("update-patient");
            (this as any).canceltransfer();
          })
          .catch((error: any) => {
            let mess = error.response.data.message;

            if (mess.includes("[")) {
              mess = mess.replace("[", "");
              mess = mess.replace("]", "");
            }
            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      });
      if (
        (this as any).contador == (this as any).transfer.transferitems.length
      ) {
        this.$emit("update-patient");
        (this as any).canceltransfer();
      }
    },
    paymentDate(date: string) {
      return new Date(date).toISOString().slice(0, 10);
    },

    canceltransfer() {
      (this as any).contador = 0;
      (this as any).transfer = {
        transferdialog: false,

        transferitems: [],
        transferamount: 0,
        transferToProcedure: "",
      };
    },
    toEdit(item: any) {
      (this as any).dialogEdit = true;
      (this as any).idSelect = item;
    },

    cancelEditProc() {
      (this as any).dialogEdit = false;
      (this as any).surgicalDate = null;
      (this as any).loadingEditPro = false;
      (this as any).idSelect = null;
    },
    confirmEditProc() {
      (this as any).loadingEditPro = true;
      const date = moment((this as any).surgicalDate)
        .utc()
        .toISOString();
      getAPI
        .post("/sprocedures/updateProcedure/" + (this as any).idSelect.id, {
          surgicalDate: date,
        })
        .then(() => {
          (this as any).cancelEditProc();
        })
        .catch((error) => {
          (this as any).loadingEditPro = false;
          let mess = error.response.data.message.toString();

          notifyError(error.response.data, `An error occurred: ${mess}`);
        });
    },
  },
});
